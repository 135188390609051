<template>
  <div class="wrapper-loadding" v-loading="!loaded" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="wrapper" v-if="loaded">
      <div v-if="!isMobileDevice" class="pc-wrapper">
        <div class="content-wrapper">
          <div class="head-wrapper">
            <div class="title-wrapper">
              <div class="title">企业财税分析报告</div>
              <div class="sub-title" v-if="!isDesensitizationMode">
                {{ data.editionName }}-{{ data.zqName }}
              </div>
              <div class="sub-title" v-if="isDesensitizationMode">专业版-2022年</div>
              <div class="date">{{ data.checkTime | timeFormat }}</div>
            </div>

            <!--            <template v-if="!isDesensitizationMode">-->
            <!--              <router-link-->
            <!--                v-if="data.isSimple==0"-->
            <!--                :to="detailRouterObject"-->
            <!--                class="link-view-body"-->
            <!--              >查看详细报告 <i class="el-icon-d-arrow-right animate__animated animate__bounce"></i>-->
            <!--              </router-link-->
            <!--              >-->

            <!--              <router-link-->
            <!--                v-else-->
            <!--                :to="detailRouterObject"-->
            <!--                class="link-view-body"-->
            <!--              >查看详细报告 <i class="el-icon-d-arrow-right animate__animated animate__bounce"></i>-->
            <!--              </router-link-->
            <!--              >-->
            <!--            </template>-->
          </div>
          <div class="body-wrapper">
            <div class="section-wrapper">
              <div class="section-title">
                <div class="decorator"></div>
                基本信息
              </div>
              <div class="section-body">
                <div class="company-name-wrapper">
                  <div class="name">
                    {{ data.companyName }}
                  </div>
                  <div class="attributes">
                    <div class="attribute" :class="attributeColor(index)" v-for="(tag, index) in data.tags" :key="index">
                      {{ tag }}
                    </div>
                  </div>
                </div>

                <div class="basic-infos">
                  <div class="basic-info-item">纳税人识别号：{{ data.taxNo }}</div>
                  <div class="basic-info-item">所属行业：{{ data.hymc }}</div>
                  <div class="basic-info-item">
                    纳税人资质：{{ data.taxpayerQualificationType }}
                  </div>
                  <div class="basic-info-item">参保人数：{{ data.socialStaffNum }}</div>
                  <div class="basic-info-item">注册资本：{{ data.regCapital }}</div>
                  <div class="basic-info-item">实收资本：{{ data.actualCapital }}</div>
                  <div class="basic-info-item">进出口信用：{{ data.managementCategory }}</div>
                  <div class="basic-info-item">成立日期：{{ data.estiblishTime | dateFormat }}</div>
                  <div class="basic-info-item">核准日期：{{ data.approvedTime | dateFormat }}</div>
                  <!--                  <template v-if="data.managementCategory!='无'">-->
                  <!--                    <div class="basic-info-item">报关有效期：{{ data.validityDate }}</div>-->
                  <!--                    <div class="basic-info-item">海关注销标志：{{ data.status}}</div>-->
                  <!--                  </template>-->
                </div>
              </div>
            </div>
            <div class="section-wrapper">
              <div class="section-title">
                <div class="decorator"></div>
                风险概览
              </div>
              <div class="section-body">
                <div class="risk-summary">
                  <div class="risk-summary-item">
                    <template v-if="risksHit">
                      <div class="title">风险命中数量</div>
                      <div class="section-body">
                        <SolidRingForResult :data="risksHit" @click="showOnlyRiskLevel"></SolidRingForResult>
                      </div>
                    </template>
                  </div>
                  <div class="risk-summary-item">
                    <div class="title">风险评分</div>
                    <div class="section-body">
                      <Qiu360ForResult class="qiu360-box white-box" :level="data.grade - 1"></Qiu360ForResult>
                    </div>
                  </div>
                  <div class="risk-summary-item">
                    <template v-if="risksPercentage">
                      <div class="title">风险占比</div>
                      <div class="section-body">
                        <SolidPieForResult :data="risksPercentage"></SolidPieForResult>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="section-wrapper" style="padding-bottom: 0">
              <div class="section-head">
                <div class="section-title" id="fengXianCiYun">
                  <div class="decorator"></div>
                  风险词云
                </div>
                <Legends name="风险词云" :legends="[
                  { color: '#EA6C5B', text: '高风险' },
                  { color: '#F7C122', text: '中风险' },
                  { color: '#63DAAB', text: '低风险' },
                ]"></Legends>
              </div>
              <div class="section-body">
                <!--            <Danmu class="danmu" ref="danmu"></Danmu>-->
                <Ciyun legendsName="风险词云" class="danmu" ref="danmu" :data="data.riskWordCloudList"
                  @click="handleCiyunClick"></Ciyun>
              </div>
            </div>
            <div class="section-wrapper" style="padding-bottom: 0" v-if="runningAbility">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  财务分析
                </div>
              </div>
              <div class="section-body">
                <RunningAbility :data="runningAbility"></RunningAbility>
              </div>
            </div>
            <div class="section-wrapper" style="padding-bottom: 0" v-if="runningAnalysis">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  财务分析-关系图
                </div>
              </div>
              <div class="section-body">
                <RunningAnalysis :data="runningAnalysis"></RunningAnalysis>
              </div>
            </div>
            <div class="section-wrapper" v-if="keyFinanceProportion">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  关键财务比率
                  <Legends style="margin-left: 50px" name="关键财务比率" :legends="[
                    { color: '#409EFF', text: '本期' },
                    { color: '#F7C122', text: '上期' },
                    { color: '#63DAAB', text: '行业' },
                  ]"></Legends>
                </div>

                <el-radio-group v-model="financeRatioTab">
                  <el-radio-button label="图形">图形</el-radio-button>
                  <el-radio-button label="表格">表格</el-radio-button>
                </el-radio-group>
              </div>
              <div class="section-body">
                <FinanceRatio legendsName="关键财务比率" :data="keyFinanceProportion" v-if="financeRatioTab == '图形'">
                </FinanceRatio>
                <div v-if="financeRatioTab == '表格'">
                  <KeyFinanceTable :data="keyFinanceTableData"></KeyFinanceTable>
                </div>
              </div>
            </div>

            <div class="section-wrapper" v-if="inOutCompare">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  购销金额对比
                </div>
              </div>
              <div class="section-body">
                <Column2Chart class="inOutCompare" :chartData="inOutCompare.jsonData"></Column2Chart>
              </div>
            </div>
            <div class="section-wrapper" v-if="qian10daCaigou">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  购销品类对比
                </div>
              </div>
              <div class="section-body">
                <Qian10da :data="qian10daCaigou" class="qian10da" :index="1" :colors="colors"></Qian10da>
                <Qian10da :data="qian10daXiaoshou" class="qian10da" :index="2" :colors="colors"></Qian10da>
              </div>
            </div>

            <div class="section-wrapper" v-if="supplierAndCustomer &&
              Object.values(supplierAndCustomer.jsonData.supplierList).length > 0
              ">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  供应商分布
                </div>
              </div>
              <div class="section-body">
                <ChinaCityTable :data="supplierAndCustomer" :type="'2'"></ChinaCityTable>
              </div>
            </div>
            <div class="section-wrapper" v-if="supplierAndCustomer &&
              Object.values(supplierAndCustomer.jsonData.customerList).length > 0
              ">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  客户分布
                </div>
              </div>
              <div class="section-body">
                <ChinaCityTable :data="supplierAndCustomer" :type="'1'"></ChinaCityTable>
              </div>
            </div>
            <div class="section-wrapper" v-if="upDownStreamRisk && upDownStreamRisk.jsonData">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  上下游风险分析
                </div>
                <div class="tip">注:红色表示该企业存在风险</div>
              </div>
              <div class="section-body">
                <UpstreamAndDownstreamRisk :up-streams="upDownStreamRisk.jsonData.topTenSupplierList"
                  :down-streams="upDownStreamRisk.jsonData.topTenCustomerList"
                  :upstreamAndDownstreamCurrentNode="{ name: data.companyName }"></UpstreamAndDownstreamRisk>
              </div>
            </div>
          </div>
        </div>
        <!-- <ShowShareCode ref="ShowShareCode" :companyName="data.companyName"
          v-if="!$route.name.includes('share') && !isDesensitizationMode" :uuid="uuid"></ShowShareCode> -->
        <img src="@/assets/result/detail-report.png" class="codeBtnShare" @click="handleDetailReportClick" />
      </div>
      <div class="mobile-wrapper" v-else>
        <div class="content-wrapper">
          <div class="head-wrapper">
            <div class="title">企业财税分析报告</div>
            <div class="sub-title">{{ data.editionName }}-{{ data.zqName }}</div>
            <div class="date">{{ data.checkTime | timeFormat }}</div>
            <router-link :to="detailRouterObject" class="link-view-body">查看详细报告>>>
            </router-link>
          </div>
          <div class="body-wrapper">
            <div class="section-wrapper">
              <div class="section-title">
                <div class="decorator"></div>
                基本信息
              </div>
              <div class="section-body">
                <div class="company-name-wrapper">
                  <div class="name">
                    {{ data.companyName }}
                  </div>
                  <div class="attributes">
                    <div class="attribute" :class="attributeColor(index)" v-for="(tag, index) in data.tags" :key="index">
                      {{ tag }}
                    </div>
                  </div>
                </div>
                <div class="basic-infos">
                  <div class="basic-info-item">纳税人识别号：{{ data.taxNo }}</div>
                  <div class="basic-info-item">所属行业：{{ data.hymc }}</div>
                  <div class="basic-info-item">
                    纳税人资质：{{ data.taxpayerQualificationType }}
                  </div>
                  <div class="basic-info-item">参保人数：{{ data.socialStaffNum }}</div>
                  <div class="basic-info-item">注册资本：{{ data.regCapital }}</div>
                  <div class="basic-info-item">实收资本：{{ data.actualCapital }}</div>
                  <div class="basic-info-item">进出口信用：{{ data.managementCategory }}</div>
                  <div class="basic-info-item">成立日期：{{ data.estiblishTime | dateFormat }}</div>
                  <div class="basic-info-item">核准日期：{{ data.approvedTime | dateFormat }}</div>
                </div>
              </div>
            </div>
            <div class="section-wrapper">
              <div class="section-title">
                <div class="decorator"></div>
                风险概览
              </div>
              <div class="section-body">
                <div class="risk-summary">
                  <div class="risk-summary-item">
                    <div class="title">风险评分</div>
                    <div class="section-body">
                      <Qiu360ForResultForMobile class="qiu360-box white-box" :level="data.grade - 1">
                      </Qiu360ForResultForMobile>
                    </div>
                  </div>

                  <div class="risk-summary-item">
                    <template v-if="risksHit">
                      <div class="title">风险命中数量</div>
                      <div class="section-body">
                        <SolidRingForResultForMobile :data="risksHit" direction="row"></SolidRingForResultForMobile>
                      </div>
                    </template>
                  </div>

                  <div class="risk-summary-item">
                    <template v-if="risksPercentage">
                      <div class="title">风险占比</div>
                      <div class="section-body">
                        <SolidPieForResultForMobile :data="risksPercentage"></SolidPieForResultForMobile>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="section-wrapper" style="padding-bottom: 0" v-if="runningAbility">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  财务分析
                </div>
              </div>
              <div class="section-body">
                <RunningAbilityMobile :data="runningAbility"></RunningAbilityMobile>
              </div>
            </div>
            <div class="section-wrapper" style="padding-bottom: 0" v-if="runningAnalysis">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  财务分析-关系图
                </div>
              </div>
              <div class="section-body">
                <RunningAnalysisMobile :data="runningAnalysis"></RunningAnalysisMobile>
              </div>
            </div>
            <div class="section-wrapper" v-if="keyFinanceProportion">
              <div class="section-title">
                <div class="decorator"></div>
                关键财务比率
                <el-radio-group v-model="financeRatioTab" class="financeRatioTab radio-tab-mobile">
                  <el-radio-button label="图形">图形</el-radio-button>
                  <el-radio-button label="表格">表格</el-radio-button>
                </el-radio-group>
              </div>
              <div class="section-body">
                <div v-if="financeRatioTab == '图形'">
                  <Legends name="关键财务比率" :legends="[
                    { color: '#409EFF', text: '本期' },
                    { color: '#F7C122', text: '上期' },
                    { color: '#63DAAB', text: '行业' },
                  ]" :disable="true"></Legends>
                  <FinanceRatio labelWidth="110px" legendsName="关键财务比率" v-if="keyFinanceProportion"
                    :data="keyFinanceProportion"></FinanceRatio>
                </div>
                <div v-if="financeRatioTab == '表格'">
                  <KeyFinanceTable :data="keyFinanceTableData"></KeyFinanceTable>
                </div>
              </div>
            </div>
            <!--            <div class="section-wrapper">-->
            <!--              <div class="section-head">-->
            <!--                <div class="section-title">-->
            <!--                  <div class="decorator"></div>-->
            <!--                  风险词云-->
            <!--                </div>-->

            <!--              </div>-->
            <!--              <div class="section-body">-->
            <!--                <Legends name="风险词云" :disable="true" :legends="[-->
            <!--                {color: '#EA6C5B', text: '高风险'},-->
            <!--                {color: '#F7C122', text: '中风险'},-->
            <!--                {color: '#63DAAB', text: '低风险'},-->
            <!--              ]"></Legends>-->
            <!--                <CiyunMobile legendsName="风险词云" class="danmu" ref="danmu"-->
            <!--                             :data="data.riskWordCloudList"-->
            <!--                             @click="handleCiyunClick"></CiyunMobile>-->
            <!--                &lt;!&ndash;            <Danmu class="danmu" ref="danmu"></Danmu>&ndash;&gt;-->
            <!--                &lt;!&ndash;              <CiyunMobile class="danmu" ref="danmu" :data="data.riskWordCloudList"></CiyunMobile>&ndash;&gt;-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="section-wrapper" v-if="inOutCompare">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  购销金额对比
                </div>
              </div>
              <div class="section-body">
                <Column2ChartMobile style="margin-top: 20px" class="inOutCompare" :chartData="inOutCompare.jsonData">
                </Column2ChartMobile>
              </div>
            </div>
            <div class="section-wrapper" v-if="qian10daCaigou">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  购销品类对比
                </div>
                <el-radio-group v-model="qian10daTab" class="radio-tab-mobile">
                  <el-radio-button label="图形">图形</el-radio-button>
                  <el-radio-button label="表格">表格</el-radio-button>
                </el-radio-group>
              </div>
              <div class="section-body">
                <Qian10daMobile :mode="qian10daTab" :data="qian10daCaigou" class="qian10da" :index="1" :colors="colors">
                </Qian10daMobile>
                <Qian10daMobile :mode="qian10daTab" :data="qian10daXiaoshou" class="qian10da" :index="2" :colors="colors">
                </Qian10daMobile>
              </div>
            </div>

            <div class="section-wrapper" v-if="supplierAndCustomer &&
              Object.values(supplierAndCustomer.jsonData.supplierList).length > 0
              ">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  供应商分布
                </div>
              </div>
              <div class="section-body">
                <ChinaCityTableMobile :data="supplierAndCustomer" :type="'2'"></ChinaCityTableMobile>
                <!--              <ChinaCityMobile :geo-coord-map="supplierAndCustomer.jsonData.shAreaMap"-->
                <!--                               :value-data="supplierAndCustomer.jsonData.supplierMap"></ChinaCityMobile>-->
              </div>
            </div>
            <div class="section-wrapper" v-if="supplierAndCustomer &&
              Object.values(supplierAndCustomer.jsonData.customerList).length > 0
              ">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  客户分布
                </div>
              </div>
              <div class="section-body">
                <ChinaCityTableMobile :data="supplierAndCustomer" :type="'1'"></ChinaCityTableMobile>
                <!--              <ChinaCityMobile :geo-coord-map="supplierAndCustomer.jsonData.shAreaMap"-->
                <!--                               :value-data="supplierAndCustomer.jsonData.customerMap"></ChinaCityMobile>-->
              </div>
            </div>
            <div class="section-wrapper" v-if="upDownStreamRisk && upDownStreamRisk.jsonData">
              <div class="section-head">
                <div class="section-title">
                  <div class="decorator"></div>
                  上下游风险分析
                </div>
                <div class="main-tip">注:红色表示该企业存在风险。</div>
              </div>

              <div class="section-body" style="padding-top: 20px">
                <UpstreamAndDownstreamRiskMobile :up-streams="upDownStreamRisk.jsonData.topTenSupplierList"
                  :down-streams="upDownStreamRisk.jsonData.topTenCustomerList"
                  :upstreamAndDownstreamCurrentNode="{ name: data.companyName }"></UpstreamAndDownstreamRiskMobile>

                <!--              <div class="sub-title">供应商</div>-->
                <!--              <UpstreamAndDownstreamRiskMobile :data="upDownStreamRisk.jsonData.topTenSupplierList"-->
                <!--              ></UpstreamAndDownstreamRiskMobile>-->
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="main-tip" style="margin-bottom: 20px;padding: 10px;">-->
        <!--          *进入PC端查看更多风险详情及解决方案-->
        <!--        </div>-->
      </div>
      <template v-if="isMobileDevice">
        <ShowShareCodeMobile :isMobile="true" ref="ShowShareCodeMobile" :companyName="data.companyName"
          v-if="!$route.name.includes('share') && !isDesensitizationMode" :uuid="uuid"></ShowShareCodeMobile>
        <img src="@/assets/result/mobile-detail-report.png" class="mobileCodeBtnShare" @click="handleDetailReportClick" />
      </template>
    </div>
  </div>
</template>

<script>
import { getVisualReport } from "../../api/api";

import Legends from "./components/ResultHead/Legends";
import ChinaCity from "./components/ResultHead/ChinaCity";
import UpstreamAndDownstreamRisk from "./components/ResultHead/UpstreamAndDownstreamRisk";
import FinanceRatio from "./components/ResultHead/FinanceRatio";
import Ciyun from "./components/ResultHead/Ciyun";

import SolidRingForResult from "./components/ResultHead/SolidRingForResult/SolidRingForResult";
import SolidPieForResult from "./components/ResultHead/SolidPieForResultHead/SolidPieForResult";
import Qiu360ForResult from "./components/ResultHead/Qiu360ForResult";
import Column2Chart from "./components/ResultHead/Column2Chart";
import Qian10da from "./components/ResultHead/Qian10da";
import SolidRingForResultForMobile from "./components/ResultHead/SolidRingForResult/SolidRingForResultForMobile";
import Qiu360ForResultForMobile from "./components/ResultHead/Qiu360ForResultForMobile";
import SolidPieForResultForMobile from "./components/ResultHead/SolidPieForResultHead/SolidPieForResultForMobile";
import FinanceRatioTable from "./components/ResultHead/FinanceRatioTable";
import Column2ChartMobile from "./components/ResultHead/Column2ChartMobile";
import Qian10daMobile from "./components/ResultHead/Qian10daMobile";
import ChinaCityMobile from "./components/ResultHead/ChinaCityMobile";
import CiyunMobile from "./components/ResultHead/CiyunMobile";
import UpstreamAndDownstreamRiskMobile from "./components/ResultHead/UpstreamAndDownstreamRiskMobile";
import ShowShareCode from "./components/compents/ShowShareCode";
import KeyFinanceTable from "./components/ResultHead/KeyFinanceTable";
import { getAutoRoutePushTo, getDesensitizationFromRouter } from "../../utils/helper";
import ChinaCityTable from "./components/ResultHead/chinaCityTable/ChinaCityTable";
import ChinaCityTableMobile from "./components/ResultHead/chinaCityTable/ChinaCityTableMobile";
import RunningAbilityMobile from "@/pages/Result/components/ResultHead/RunningAbilityMobile";
import RunningAnalysis from "@/pages/Result/components/ResultHead/RunningAnalysis/RunningAnalysis";
import RunningAnalysisMobile from "@/pages/Result/components/ResultHead/RunningAnalysis/RunningAnalysisMobile";
import RunningAbility from "@/pages/Result/components/ResultHead/RunningAbility";
import specialResultHeadMixin from "@/pages/Result/specialResultHeadMixin";
import ShowShareCodeMobile from "@/pages/Result/components/compents/ShowShareCodeMobile";

export default {
  name: "ResultHead",
  components: {
    RunningAbility,
    RunningAnalysisMobile,
    RunningAbilityMobile,
    ChinaCityTableMobile,
    ChinaCityTable,
    ShowShareCode,
    RunningAnalysis,
    FinanceRatioTable,
    ShowShareCodeMobile,
    SolidPieForResultForMobile,
    SolidRingForResultForMobile,
    Column2Chart,
    SolidRingForResult,
    SolidPieForResult,
    Legends,
    UpstreamAndDownstreamRisk,
    UpstreamAndDownstreamRiskMobile,
    ChinaCity,
    FinanceRatio,
    Ciyun,
    CiyunMobile,
    Qiu360ForResult,
    Qian10da,
    Qiu360ForResultForMobile,
    Column2ChartMobile,
    Qian10daMobile,
    KeyFinanceTable,
    ChinaCityMobile,
  },
  mixins: [specialResultHeadMixin],
  created() { },
  metaInfo() {
    return {
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0,  minimum-scale=1.0, user-scalable=yes, target-densitydpi=device-dpi",
        },
      ],
    };
  },
  props: {
    uuid: String,
  },
  data() {
    return {
      //风险命中数量
      loaded: false,
      financeRatioTab: "图形",
      qian10daTab: "图形",
      data: [],
      colors: [
        "#E86452",
        "#F6BD16",
        "#5AD8A6",
        "#6293F8",
        "#1D73C4",
        "#541DC4",
        "#599EF7",
        "#59F78C",
        "#F7A659",
        "#F75959",
        "#000000",
      ],
    };
  },
  computed: {
    isMobileDevice() {
      return window.innerWidth < 600;
    },
    routeName() {
      return this.$route.name;
    },
    detailRouterObject() {
      return getAutoRoutePushTo(
        this.uuid,
        this.$route,
        this.data.isSimple,
        {},
        this.isMobileDevice
      );
    },
    risksHit() {
      if (this.data.highRisk + this.data.middleRisk + this.data.lowRisk == 0) {
        return false;
      }
      const data = [
        {
          name: "高风险",
          value: this.data.highRisk,
        },
        {
          name: "中风险",
          value: this.data.middleRisk,
        },
        {
          name: "低风险",
          value: this.data.lowRisk,
        },
      ];

      return data;
    },
    //风险占比
    risksPercentage() {
      if (!this.data.riskProportion) {
        return false;
      }
      const data = Object.entries(this.data.riskProportion).map(([name, value]) => {
        return {
          value,
          name,
        };
      });

      return data;
    },
    //关键财务比率
    keyFinanceProportion() {
      return this.data.summaryModelDataDtos.find((v) => v.fixedKey == "summaryModel007");
    },
    //关键财务表格
    keyFinanceTableData() {
      return this.data.summaryModelDataDtos.find((v) => v.fixedKey == "MinCompany001");
    },
    //进销项对比
    inOutCompare() {
      return this.data.summaryModelDataDtos.find((v) => v.fixedKey == "summaryModel003");
    },
    //前十大采购品类风险分析
    qian10daCaigou() {
      return this.data.summaryModelDataDtos.find((v) => v.fixedKey == "summaryModel005");
    },
    //前十大销售品类风险分析
    qian10daXiaoshou() {
      return this.data.summaryModelDataDtos.find((v) => v.fixedKey == "summaryModel006");
    },
    //客户及供应商分布
    supplierAndCustomer() {
      return this.data.summaryModelDataDtos.find((v) => v.fixedKey == "summaryModel002");
    },
    //上下游风险分析
    upDownStreamRisk() {
      return this.data.summaryModelDataDtos.find((v) => v.fixedKey == "summaryModel001");
    },
    //经营能力
    runningAbility() {
      const data = this.data.summaryModelDataDtos.find((v) => v.fixedKey == "summaryModel004");
      if (!data) {
        return null;
      }
      return data.jsonData["财务分析"];
    },
    //财务分析-关系图
    runningAnalysis() {
      const data = this.data.summaryModelDataDtos.find((v) => v.fixedKey == "summaryModel004");
      if (!data) {
        return null;
      }
      return data.jsonData["杜邦分析"];
    },
  },
  async mounted() {
    if (this.$route.query.token) {
      localStorage.setItem("authorization", this.$route.query.token);
    }
    await this.init();
    this.$eventBus.$on("ringChartClick", (param) => {
      this.$store.commit("Result/showOnlyResultHeadLegends", {
        groupName: "风险词云",
        index: param.dataIndex,
      });
    });
    console.log(this.$router);
  },
  methods: {
    handleDetailReportClick() {
      this.$router.push(this.detailRouterObject);
    },
    handleCiyunClick(data) {
      this.$router.push(
        getAutoRoutePushTo(this.uuid, this.$route, this.data.isSimple, {
          anchor: data.id,
        })
      );
      //const fullURL = new URL(props.href, window.location.href).href;
      //location.href=fullURL;
    },

    //只显示当前的风险显示
    showOnlyRiskLevel(riskLevelIndex) {
      this.riskLevelShow = [false, false, false];
      this.riskLevelShow[riskLevelIndex] = true;
      this.$refs.danmu.changeFilteredData(this.riskLevelShow);
    },
    //切换当前风险显示
    toggleRiskLevel(riskLevelIndex) {
      this.riskLevelShow[riskLevelIndex] = !this.riskLevelShow[riskLevelIndex];
      this.$refs.danmu.changeFilteredData(this.riskLevelShow);
    },
    async init() {
      const res = await getVisualReport(this.uuid, getDesensitizationFromRouter());
      this.data = res.data;

      // this.$router.push(this.detailRouterObject)
      if (!this.data.hasData) {
        this.loaded = false;
        if (this.$route.name === "result-head-share2") {
          this.$router.replace(getAutoRoutePushTo(
            this.uuid,
            this.$route,
            this.data.isSimple,
            {},
            this.isMobileDevice,
            true
          ));
        } else {
          this.$router.replace(this.detailRouterObject);
        }
      } else {
        this.loaded = true;
      }
    },
    attributeColor(index) {
      const classes = ["blue", "yellow", "green", "red"];
      return classes[index % classes.length];
    },
  },
};
</script>
<style>
@media screen and (max-width: 800px) {
  body {
    min-width: auto;
  }
}
</style>
<style lang="stylus" scoped>
.wrapper-loadding
  width 100%
  height 100vh
  box-sizing border-box

.wrapper
  overflow hidden
  background #fbfbfb

  .pc-wrapper

    .content-wrapper
      box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
      padding 10px 0
      border-radius: 8px;
      width 1280px;
      margin 50px auto;
      background #fff;

      .head-wrapper
        display flex
        align-items center;
        justify-content center;
        margin-top 80px
        position relative;

        .title-wrapper
          text-align center;

          .title
            color #282828
            font-size: 48px;
            font-weight: bold;

          .sub-title
            margin-top 20px
            font-size: 20px;
            text-align center;
            color #666

          .date
            font-size: 14px;
            margin-top 20px
            color #999
            text-align center


        .link-view-body
          color #207DFB
          position absolute;
          right 20px;
          top 50%;
          font-size 24px

      .body-wrapper

        margin-top 59px


        .section-wrapper
          padding 36px 36px;
          border-top 1px dashed rgba(32, 125, 251, 0.4);
          margin-bottom 24px

          .section-head
            display flex
            justify-content space-between
            align-items center;

          .tip
            color #ec5757;
            font-size 14px
            margin-right 30px
            position relative;
            top: -20px

          .section-title
            font-weight: 500;
            color: rgba(0, 0, 0, 0.8);
            line-height: 47px;
            display flex
            font-size: 32px;
            align-items center
            margin-bottom 50px

            .decorator
              background rgba(32, 125, 251, 1)
              width 11px
              height 25px
              margin-right 17px

          .section-body
            font-size 16px
            color: rgba(0, 0, 0, 0.8);
            line-height: 24px;

            .company-name-wrapper
              margin-top 20px
              display flex

              .name
                font-size: 20px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.8);
                line-height: 29px;
                flex-grow 0
                flex-shrink 0
                margin-right 41px

              .attributes
                display flex
                flex-wrap wrap
                flex-grow 0
                flex-shrink 1

                .attribute
                  flex-shrink 0
                  background: #ECF5FF;
                  border-radius: 4px;
                  border: 1px solid #B3D8FF;
                  padding 3px
                  color: #409EFF;
                  margin 5px 10px
                  font-size 12px
                  line-height 12px
                  align-items center;
                  vertical-align: middle
                  font-weight: 500;

                .attribute.blue
                  background: #ECF5FF;
                  border: 1px solid #B3D8FF;
                  color: #409EFF;

                .attribute.yellow
                  background: #FDF6EC;
                  border: 1px solid #F5DAB1;
                  color: #E6A23C;

                .attribute.green
                  background: #F0F9EB;
                  border: 1px solid #C2E7B0;
                  color: #67C23A;

                .attribute.red
                  background: #FEF0F0;
                  border: 1px solid #FBC4C4;
                  color: #F56C6C;

            .basic-infos
              margin-top 40px
              display flex;
              flex-wrap wrap;

              .basic-info-item
                width 33.3%
                margin-bottom 16px

            .risk-summary
              margin-top 41px
              display flex

              .risk-summary-item
                width 33%;

                .title
                  font-size: 20px;
                  margin-bottom 15px

            .inOutCompare
              width: 100%;
              height: 300px;


            .danmu
              width 100%;
              margin-top 24px

.wrapper
  background #fbfbfb

  .main-tip
    font-size 12px;
    color #ff0000

  .mobile-wrapper
    margin 0px
    background #fff;
    overflow hidden

    .content-wrapper
      width 100%;
      box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);

      .head-wrapper
        display flex
        align-items center;
        justify-content center;
        padding-top 30px
        position relative;
        flex-direction column;

        .title
          color #282828
          font-size: 20px;
          text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
          color: rgba(0, 0, 0, 0.6);
          font-weight: bold;

        .sub-title
          font-size: 16px;
          margin-top 20px
          text-align center;
          color #666
          margin-bottom 20px

        .date
          font-size: 14px;
          margin-bottom 10px
          color #666

        .link-view-body
          display none;

      .body-wrapper
        margin-top 20px
        margin 20px 5px 0

        .section-wrapper
          padding 20px 0;
          border-top 1px dashed rgba(32, 125, 251, 0.4);
          border-bottom 1px dashed rgba(32, 125, 251, 0.4);
          margin-bottom 24px

          .section-head
            display flex
            justify-content space-between
            align-items center;

          .section-title
            font-weight: 500;
            color: rgba(0, 0, 0, 0.8);
            line-height: 47px;
            display flex
            font-size: 18px;
            align-items center
            position relative

            .financeRatioTab
              position absolute;
              right 5px

            .decorator
              background rgba(32, 125, 251, 1)
              width 11px
              height 18px
              margin-right 17px

          .section-body
            font-size 16px
            color: rgba(0, 0, 0, 0.8);
            line-height: 24px;

            >>> .summary-evaluate
              margin-top 10px
              margin-bottom 10px

            >>> .table
              margin-top 10px

              .el-table
                margin-top 0

            .sub-title
              margin-top 20px
              margin-bottom 10px
              text-align center;
              font-size 14px;
              font-weight bold;

            .company-name-wrapper
              display flex
              flex-direction column

              .name
                font-size: 16px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.8);
                line-height: 29px;
                flex-grow 0
                flex-shrink 0
                margin-right 41px

              .attributes
                display flex
                flex-wrap wrap
                flex-grow 0
                flex-shrink 1

                .attribute
                  flex-shrink 0
                  background: #ECF5FF;
                  border-radius: 4px;
                  border: 1px solid #B3D8FF;
                  padding 3px
                  color: #409EFF;
                  margin 5px 10px
                  margin-left 0
                  font-size 12px
                  line-height 12px
                  align-items center;
                  vertical-align: middle
                  font-weight: 500;

                .attribute.blue
                  background: #ECF5FF;
                  border: 1px solid #B3D8FF;
                  color: #409EFF;

                .attribute.yellow
                  background: #FDF6EC;
                  border: 1px solid #F5DAB1;
                  color: #E6A23C;

                .attribute.green
                  background: #F0F9EB;
                  border: 1px solid #C2E7B0;
                  color: #67C23A;

                .attribute.red
                  background: #FEF0F0;
                  border: 1px solid #FBC4C4;
                  color: #F56C6C;

            .basic-infos
              margin-top 10px
              display flex;
              color: rgba(0, 0, 0, 0.6);
              flex-wrap wrap;
              flex-direction column;

              .basic-info-item
                width 100%
                margin-bottom 8px

            .risk-summary
              margin-top 41px
              display flex
              flex-direction column;

              .risk-summary-item
                width 100%;
                margin-bottom 30px
                display flex;
                justify-content center;
                align-items center;
                flex-direction column;

                .title
                  font-size: 16px;
                  margin-bottom 15px
                  align-self start
                  color: rgba(0, 0, 0, 0.6);

            .inOutCompare
              width: 100%;
              height: 300px;


            .danmu
              width 100%;
              height 350px

.radio-tab-mobile
  >>> .el-radio-button__inner

    width: 74px;
    height: 32px;
    display flex;
    align-items center;
    background: #FFFFFF;
    border-radius: 0px 4px 4px 0px;
    border: 1px solid #D9D9D9;
    color: rgba(0, 0, 0, 0.4);
    outline: none !important;

  >>> .is-active .el-radio-button__inner
    background: #ECF5FF;
    border-radius: 4px 0px 0px 4px;
    border: 1px solid #6293F8;
    color: #6293F8;


.codeBtnShare {
  position fixed;
  right 1%;
  top calc(50% + 60px);
  transform translateY(-50%)
  width 160px
  cursor pointer;
  display flex;
  justify-content center;
  align-items center;
  flex-direction column
  color #2F54EB
  font-weight bold;
  font-size 16px
}
.mobileCodeBtnShare {
  position fixed;
  right 8px;
  top 50px;
  width 54px
  cursor pointer;
  display flex;
  justify-content center;
  align-items center;
  flex-direction column
  color #2F54EB
  font-weight bold;
  font-size 16px
}
</style>
