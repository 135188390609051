<template>
  <div v-if="loaded">
    <div class="legend-wrapper">

      <div class="legend" v-for="(legend,index) in legends" :key="index"
           @click="handleLegendClick({...legend,index})"
           :class="{actived:$store.state.Result.resultHeadLegends[name]&&$store.state.Result.resultHeadLegends[name][index]}">
        <div class="symbol" v-if="legend.symbol" :style="{'color': legend.color}">
          {{ legend.symbol }}
        </div>
        <div class="box" v-else-if="legend.color" :style="{'background': legend.color}"></div>
        <div class="text">
          {{ legend.text }}
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "legends",
  props: {
    name: String,
    legends: {
      type: Array,
      'default': function () {
        return [

        ]
      }
    },
    disable:Boolean
  },
  data(){
    return {
      loaded:false
    }
  },
  mounted() {
    if (this.name) {
      this.$store.commit('Result/initResultHeadLegends', {
        groupName: this.name,
        size: this.legendNames.length
      });
    }
    this.loaded = true


  },
  methods: {
    handleLegendClick(data) {
      if(!this.disable){
        this.$store.commit('Result/toggleResultHeadLegends', {
          groupName: this.name,
          index: data.index
        });
      }

    }
  },
  computed: {
    legendNames() {
      return this.legends.map(v => v.name)
    }
  }
}
</script>

<style lang="stylus" scoped>
.legend-wrapper
  display flex;

  .legend
    transition all 0.1s ease-in-out
    opacity 0.5
    display flex
    align-items center;
    margin-right 20px
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    cursor pointer

    .box
      transition all 0.1s ease-in-out
      width: 24px;
      height: 13px;
      border-radius: 3px;
      margin-right 8px


    .symbol
      vertical-align top;
      position relative;
      top: -2px
      height: 13px;
      border-radius: 3px;
      margin-right 8px
  .legend:hover
    .box{
      transform scale(1.2)
    }
  .actived
    opacity 1
</style>
