<template>
  <div>
    <div ref="chartColumn" class="chartColumn" :style="{width: width,height:height}"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import echarts from "echarts";
import {getAutoRoutePushTo} from "../../../../../utils/helper";
import specialResultHeadMixin from "@/pages/Result/specialResultHeadMixin";
import {round, toFix, toFixed} from "@/utils/format";
export default {
  mounted() {
    this.showMap();
  },
  mixins:[
    specialResultHeadMixin
  ],
  props: {
    interact: {
      default:true,
      type:Boolean
    },
    colors: Array,
    data: Array,
    width: {
      default: '340px',
      type: String
    },
    height: {
      default: '250px',
      type: String
    }
  },
  methods: {
    //echart
    showMap() {
      this.$refs["chartColumn"].style.width = this.width; //window.innerWidth * 0.5 + "px";
      this.$refs["chartColumn"].style.height = this.height;
      this.chartColumn = echarts.init(this.$refs["chartColumn"]);

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: (data)=>{
            return `${data.seriesName} <br/>${data.name}: ${toFixed(data.percent)}%`
          }
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: "风险占比",
            type: "pie",
            radius: "60%",
            avoidLabelOverlap: false,
            color: this.colors,
            label: {
              show: true,
              normal: {
                show: true,
                formatter: (data)=>{
                  return toFixed(data.percent)+'%'
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "12",
                fontWeight: "bold",
              },
            },
            data: this.data,
          },
        ],
      };
      this.chartColumn.setOption(option);
      // 饼图点击跳转到指定页面
      this.chartColumn.on('click',  (param) =>{
        //console.log(param.data.id)
        if(!this.interact){
          return
        }

        if(param.data.id){
          this.$router.push(getAutoRoutePushTo(this.$route.query.uuid,this.$route,0,{anchor:param.data.id}));
        }


      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartColumn {
  width: 350px;
  height: 300px;
}
</style>
