import { render, staticRenderFns } from "./RadarChartMobile.vue?vue&type=template&id=184942fc&scoped=true"
import script from "./RadarChartMobile.vue?vue&type=script&lang=js"
export * from "./RadarChartMobile.vue?vue&type=script&lang=js"
import style0 from "./RadarChartMobile.vue?vue&type=style&index=0&id=184942fc&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "184942fc",
  null
  
)

export default component.exports