<template>
  <div class="bar-wrapper">
    <div class="item" v-for="(item,index) in data_" :key="index">
      <el-tooltip effect="dark" placement="top-start" open-delay="300" v-if="item.tip">
        <div slot="content">
          {{ item.tip }}
        </div>
        <i class="el-icon-warning-outline icon-tip" style="margin-right: 5px"></i>
      </el-tooltip>
      <div class="label" :style="{width:labelWidth}">{{ item.name }}</div>
      <ProgressBar class="ProgressBar" :bq="item.bq" :sq="item.sq" :middle="item.avg"
                   :name="item.name" :middle-label="item.industryAverage" :bq-visible="legends[0]"
                   :sq-visible="legends[1]" :middle-visible="legends[2]"></ProgressBar>
    </div>


  </div>
</template>

<script>
import ProgressBar from "./ProgressBar";
import {mapState} from "vuex";

export default {
  components: {
    ProgressBar
  },
  props: {
    data: Object,
    legendsName: String,
    labelWidth: {
      type: String,
      default: '180px'
    }
  },
  name: "FinanceRatio",
  watch: {},
  mounted() {

  },
  computed: {
    ...mapState("Result", ["resultHeadLegends"]),
    data_() {
      return this.data.jsonData.maxList.map((v, index) => {
        return {
          sq: parseFloat(this.data.jsonData.yData[0].yData[index]),
          bq: parseFloat(this.data.jsonData.yData[1].yData[index]),
          avg: parseFloat(this.data.jsonData.yData[2].yData[index]),
          industryAverage: v.industryAverage,
          name: v.name,
          legendVisible: [true, true, true],
          tip: v.tip
        }
      })
    },
    legends() {

      return this.resultHeadLegends[this.legendsName] ? this.resultHeadLegends[this.legendsName] : [true, true, true];
    }

  }
}
</script>
<style lang="stylus" scoped>

.bar-wrapper
  .item
    margin 50px 0
    display flex;
    align-items center;
    position relative
    .label
      flex-shrink 0;
      font-weight bold;
      color #666


</style>

