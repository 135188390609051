<template>
  <div>
    <div class="wrapper">
      <div class="map">
        <ChinaCity ref="map" @click="handleMapClick" :geo-coord-map="data.jsonData.shAreaMap"
                   :type="type"
                   :value-data="valueData" :self="data.jsonData.companyDistribution"></ChinaCity>
        <div class="legends">
          <div class="legend-group">
            <div class="legend">
              <img class="symbol"
                   src="https://shuian-file.oss-cn-hangzhou.aliyuncs.com/image/icon_triangle.png">
              表示本公司地点
            </div>
          </div>
          <div class="legend-group" v-if="type==2">
            <div class="legend">
              X为采购额占比
            </div>
            <div class="legend">
              <div class="box" style="background: #207DFB"></div>
              X≥50%
            </div>
            <div class="legend">
              <div class="box" style="background: #6EAAFC"></div>
              50%＞X≥30%
            </div>
            <div class="legend">
              <div class="box" style="background: #AED0FF"></div>
              30%＞X≥20%
            </div>
            <div class="legend">
              <div class="box" style="background: #E0EDFF"></div>
              20%>X>0%
            </div>
          </div>
          <div class="legend-group" v-else-if="type==1">
            <div class="legend">
              X为销售额占比
            </div>
            <div class="legend">
              <div class="box" style="background: #207DFB"></div>
              X≥50%
            </div>
            <div class="legend">
              <div class="box" style="background: #6EAAFC"></div>
              50%＞X≥30%
            </div>
            <div class="legend">
              <div class="box" style="background: #AED0FF"></div>
              30%＞X≥20%
            </div>
            <div class="legend">
              <div class="box" style="background: #E0EDFF"></div>
              20%>X>0%
            </div>
          </div>

        </div>
      </div>
      <div class="table">


        <div v-if="citiesTableData">
          <div class="btns">
            <button class="btn btn-default btn-go-back" @click="setProvince('')">返回</button>
          </div>
          <el-table
            :data="citiesTableData"
            style="width: 100%">
            <el-table-column
              prop="cityName"
              label="前十大地区"
            >
              <template slot-scope="scope">
                {{ scope.row.cityName }}

              </template>
            </el-table-column>
            <el-table-column
              prop="money"
              :label="(type==1)?'不含税销售金额（万元）':'不含税采购金额（万元）'"
            >
            </el-table-column>
            <el-table-column
              prop="scale"
              :label="(type==1)?'销售额占比':'采购额占比'">
              <template slot-scope="scope">
                {{ scope.row.scale|percentage }}
              </template>
            </el-table-column>
            <el-table-column
              prop="companyNumber"
              label="企业数量">
            </el-table-column>
            <el-table-column
              prop="companyNumber"
              label="风险企业数量">
            </el-table-column>
          </el-table>
        </div>
        <div v-else>
          <el-table
            :data="provinceTableData"
            style="width: 100%">
            <el-table-column
              prop="cityName"
              label="前十大地区"
            >
              <template slot-scope="scope">
                <div class="clickable" v-if="scope.row.cityName!='合计'"
                     @click="setProvince(scope.row.cityName)">
                  {{ scope.row.cityName }}
                </div>
                <div v-else>
                  {{ scope.row.cityName }}
                </div>

              </template>
            </el-table-column>
            <el-table-column
              prop="money"
              :label="(type==1)?'不含税销售金额（万元）':'不含税采购金额（万元）'"
            >
            </el-table-column>
            <el-table-column
              prop="scale"
              :label="(type==1)?'销售额占比':'采购额占比'">
              <template slot-scope="scope">
                {{ scope.row.scale |percentage }}
              </template>
            </el-table-column>
            <el-table-column
              prop="companyNumber"
              label="企业数量">
            </el-table-column>
            <el-table-column
              prop="hasRiskCompanyNumber"
              label="风险企业数量">
              <template slot-scope="scope">
                <div class="clickable" v-if="scope.row.hasRiskCompanyNumber>0" @click="jumpToMiddlePageToConfig(scope.row.cityName)">
                  {{ scope.row.hasRiskCompanyNumber }}
                </div>
                <div v-else>
                  {{ scope.row.hasRiskCompanyNumber }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ChinaCity from "../ChinaCity";
import GenericTable from "../../../../ResultEditor/components/InvoiceIndex/components/GenericTable";
import {getAutoMiddlePageToConfig} from "../../../../../utils/helper";
import {round} from "../../../../../utils/format";

export default {
  name: "ChinaCityTable",
  props: {
    data: Object,
    type: String
  },
  filters:{
    round
  },
  data() {
    return {
      currentProvinceName: ""
    }
  },
  components: {
    ChinaCity,
    GenericTable
  },
  methods: {
    setProvince(provinceName) {
      this.currentProvinceName = provinceName;
      this.$refs.map.highlight(provinceName)
    },
    handleMapClick(provinceName) {
      this.setProvince(provinceName)
    },

    jumpToMiddlePageToConfig(city) {
      this.$router.push(getAutoMiddlePageToConfig(this.$route.params.uuid, this.type, this.$route, {area: city}));
    }
  },
  computed: {
    valueData() {
      if (this.type == 1) {//客户
        return this.data.jsonData.customerList
      } else {
        return this.data.jsonData.supplierList; // 供应商
      }
    },
    provinceTableData() {
      return this.valueData.filter(v=>{
        //删掉全为0的省份
        return !(v.companyNumber==0&&v.hasRiskCompanyNumber==0&&v.money==0&&v.scale==0)
      }).filter((v, i) => i <= 10);
    },
    citiesTableData() {
      const province = this.valueData.find(v => {
        return v.cityName == this.currentProvinceName
      })
      if (province) {
        return province.children.filter((v, i) => i <= 10);
      } else {
        return null;
      }
    },
    //provinceTable
    generalTableData() {

      return {
        data: this.valueData.map(v => {
          return [v['cityName'], v['money'], v['scale'], v['companyNumber'], 'aaa']
        }),
        labels: ['前十大地区', '不含税销售金额（万元）', '销售额占比', '企业数量', '风险企业数量']
      }
    },
    tips() {
      return "dasdsa"
    },
    subTables() {
      return null
    },
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  display flex
  width 100%

  .map
    width 50%
    flex-grow 0

  .table
    width 50%
    flex-grow 0
    border 1px solid #D9D9D9;
    position relative

    .btns
      display flex;
      justify-content flex-end
      position absolute;
      top -50px
      right 0

  .legend-group
    display flex
    margin-bottom 10px

  .legend
    transition all 0.1s ease-in-out
    display flex
    align-items center;
    margin-right 20px
    font-size: 14px;
    color #666

    .box
      transition all 0.1s ease-in-out
      width: 12px;
      height: 12px;
      margin-right 8px


    .symbol
      vertical-align top;
      height: 13px;
      border-radius: 3px;
      margin-right 8px

>>> .el-table
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 0px;
  min-height 100%

  td, th {
    text-align: left;
    font-size: 14px;
    padding: 8px 16px;
    border: 1px solid rgba(238, 238, 238, 1);
    color: rgba(0, 0, 0, 0.6);
    vertical-align: top;
    min-width: 100px;

    .cell {
      color: rgba(0, 0, 0, 0.6);
      padding 0
    }

    .clickable {
      color: #2F54EB;
      cursor: pointer;
      text-decoration underline
    }
  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  tr:nth-child(even) {
    td {
      background: rgba(245, 245, 245, 1);
    }
  }

  .link {
    color: rgba(47, 84, 235, 1);
  }
</style>
